<div class="header-description" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
  <div class="game-thumbnail">
    <img *ngIf="currentGame.thumbnailDesktopSquare else oldimage" src="{{ currentGame.thumbnailDesktopSquare?.url }}" alt="{{ currentGame.name }}" />
    <ng-template #oldimage>
      <img src="{{ currentGame.thumbnail?.url }}" alt="{{ currentGame.name }}" />
    </ng-template>
  </div>
  <div class="game-detail">
    <div class="game-title">{{ currentGame.name }}</div>
    <div class="game-provider">{{ currentGame.provider }}</div>
  </div>
</div>

<div class="container">
  <div class="top-container">
    <app-profile-info *ngIf="isVisible" [isAllowRedirectToClientArea]="false" [visible]="isVisible" size="small" class="dialog"></app-profile-info>
  </div>
  <div class="mobile-balance-container">
    <app-account-balance></app-account-balance>
  </div>
  <div class="deposit" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
    <div class="deposit-button" fxLayout="row" fxLayoutAlign="center center" (click)="goToDeposit()">
      <div class="button-container" fxLayout="row" fxLayoutAlign="start center">
          <div class="label"><strong i18n>deposit</strong></div>
          <div class="add-icon" fxLayoutAlign="center center" fxLayout="row">
              <img src="assets/icons/icon-add.svg" />
          </div>
      </div>
    </div>
    <div class="quit" fxLayoutAlign="center center">
      <button round-button color="yellow" (click)="quit()" i18n>quit game</button>
    </div>
  </div>
</div>
<div>
<div class="game-info" *ngIf="currentGame">
    <!-- The code below duplicates (with slightly changes) the thumbnail code from the game-dialog.component.
    Should we create a separated component for the game-thumbnail with the tags? If we create that component,
    we don't have to copy new tags for all places. But it can affect many places in code. -->
    <div class="thumbnail">
      <img *ngIf="currentGame.thumbnailDesktopSquare else oldimage" src="{{ currentGame.thumbnailDesktopSquare?.url }}" alt="{{ currentGame.name }}" />
      <ng-template #oldimage>
        <img src="{{ currentGame.thumbnail?.url }}" alt="{{ currentGame.name }}" />
      </ng-template>

      <div *ngFor="let tag of currentGame?.tag" [ngClass]="tag === 'exclusive' ? 'exclusive' : tag === 'power_weekends' ? 'power_weekends': tag === 'reel_fortune' ? 'reel_fortune' : tag === 'spin_gifts' ? 'spin_gifts': 'badge'" [ngSwitch]="tag" class="tags">
        <ng-container *ngSwitchCase="'drops_win'">
          <img src="assets/icons/drops-win-icon.png">
        </ng-container>
        <ng-container *ngSwitchCase="'cash_drops'">
          <img src="assets/icons/tag-thumbnail-mobile-kalamba.png">
        </ng-container>

        <ng-container *ngSwitchCase="'take_prize'">
          <img src="assets/icons/take-the-prize-mobile.png">
        </ng-container>

        <ng-container *ngSwitchCase="'exclusive'">
          <img src="assets/icons/tag-lemon-exclusive-mobile.svg">
        </ng-container>
        <ng-container *ngSwitchCase="'holiday_spin_splash'">
          <img src="assets/icons/holiday-spin-splash-mobile-tag-2x.png">
        </ng-container>

        <ng-container *ngSwitchCase="'powe_-weekends'" class="power-weekends">
          <img src="assets/icons/tag-power-weekends-pragmatic-mobile-2x.png">
        </ng-container>
        <ng-container *ngSwitchCase="'reel_fortune'">
          <img src="assets/icons/ROF-mobile-36x36.png">
        </ng-container>
        <ng-container *ngSwitchCase="'spin_gifts'">
          <img src="assets/icons/spin-gifts-mobile-26x40.png">
        </ng-container>


        <ng-container *ngSwitchDefault></ng-container>
      </div>
    </div>


  <div class="description">
    <div class="game-title">{{ currentGame.name }}</div>
    <div class="game-provider">{{ currentGame.provider }}</div>
    <div class="game-favourites-toggle">
      <app-game-favourites-toggle [gameId]="currentGame.gameid"></app-game-favourites-toggle>
    </div>
  </div>
</div>

<div class="last-played-panel">
   <div fxLayout="row" fxLayoutAlign="space-between center" class="wrapper">
      <div class="category-name-container" fxLayout="row" fxLayoutAlign="start center">
        <img class="category-icon" src="assets/icons/categories/icon-my-games.svg" alt="icon-my-games"/>
        <div class="category-title" i18n>My games</div>
      </div>
     <a class="all-games-container" (click)="myGames();">
       <div i18n>All</div>
       <div class="last-played-arrow"><img src="{{licenceService.getKey('arrow-icon')}}" icon-btn size="large" alt="arrow-right"/></div>
     </a>
   </div>
    <app-front-page-games [rowsCount]="1" [isOutsideCategory]="true" (click)="closeDialog(false)" [data]="gameCategory"></app-front-page-games>
</div>
    <!-- <div class="prize-boxes-container">
    <app-prize-boxes>
    </app-prize-boxes>
</div> -->
    <app-close-dialog-button (closeEvent)="closeDialog(false)"></app-close-dialog-button>
